.hero-container {
	background: url('../assets/images/habits-image.webp') center center/cover no-repeat;
	height: 100vh;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 600;
}


.hero-container > h1 {
	color: white;
	font-size:72px;
	margin-top: 10px;
	text-align: left;
	max-width: 1400px;
	margin-left: 50px;
	margin-top: 50px;
}


.hero-btn {
  margin-top: 32px;
  padding: 10px 75px;
  border-radius: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: #3EE2CA;
  color: black;
  max-width: 250px;
  margin-left: 50px;
}

.btn-mobile{
	text-decoration: none;
	color: black;
	font-size: 24px;
}



@media screen and (min-width: 1660px){
	.hero-container > h1 {
		margin-left: 250px;
	}
	.hero-btn {
		margin-left: 250px;
	}
}


@media screen and (min-width: 1366px){
	.hero-container > h1 {
		margin-left: 200px;
	}
	.hero-btn {
		margin-left: 200px;
	}
}

@media screen and (max-width: 960px){
	.hero-container > h1 {
		font-size:64px;
		margin-top: 15px;
		margin-left: 30px;
	}

	.hero-btn {
		margin-left: 30px;
	}
}

@media screen and (max-width: 768px){
	.hero-container > h1 {
		font-size: 48px;
		margin-top: 25px;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;

	}


}