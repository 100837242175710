* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}


.navbar {
  background: black;
  border-bottom: 1.5px solid #3C515B;  
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1600px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.logo-text {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}



/*.nav-item {
  height: 80px;
}
*/
.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}


.login-button {
  text-decoration: none;
  background-color: black;
  border-color: black;
    font-size: 1.2rem;
  font-weight: 600;
}

.login-button:focus {
  outline: none;
  box-shadow: none;
}

.login-button:click {
  border-color: none;
}

.login-form {
  font-size: 24px;

}

.login-input-group {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

.login-input-group:last-child {
  margin-bottom: 0;
}

.login-input-group label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: white;
}

.login-input-group input {
  outline: none;
  background: none;
  border: 1px solid white;
  font-size: 1rem;
  padding: 0.5rem;
  color: white;
  border-radius: 10px;
}

.login-input-group .submit-button {
  color: black;
  padding: 10px 75px;
  border-radius: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  margin: auto;

}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: normal;
  }

  .nav-menu.active {
    background: black;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .logo-icon {
    width: 40px;
    height: 40px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #ffffff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

}

.hero-container {
	background: url(/static/media/habits-image.6bbc7064.webp) center center/cover no-repeat;
	height: 100vh;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 600;
}


.hero-container > h1 {
	color: white;
	font-size:72px;
	margin-top: 10px;
	text-align: left;
	max-width: 1400px;
	margin-left: 50px;
	margin-top: 50px;
}


.hero-btn {
  margin-top: 32px;
  padding: 10px 75px;
  border-radius: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: #3EE2CA;
  color: black;
  max-width: 250px;
  margin-left: 50px;
}

.btn-mobile{
	text-decoration: none;
	color: black;
	font-size: 24px;
}



@media screen and (min-width: 1660px){
	.hero-container > h1 {
		margin-left: 250px;
	}
	.hero-btn {
		margin-left: 250px;
	}
}


@media screen and (min-width: 1366px){
	.hero-container > h1 {
		margin-left: 200px;
	}
	.hero-btn {
		margin-left: 200px;
	}
}

@media screen and (max-width: 960px){
	.hero-container > h1 {
		font-size:64px;
		margin-top: 15px;
		margin-left: 30px;
	}

	.hero-btn {
		margin-left: 30px;
	}
}

@media screen and (max-width: 768px){
	.hero-container > h1 {
		font-size: 48px;
		margin-top: 25px;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;

	}


}
.container {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	min-height: 100vh;	
	padding: 20px 150px 20px 100px;
}

.generic {
	margin-bottom: 50px;
}

.site-under-construction {
	margin-top: 25px;
}

.text-container {
	background-color: #393E46;
	border-radius: 10px;
	display: flex;	
	flex-direction: column;
	align-items: center;
	max-width: 500px;
}

.form-area {
	width: 100%;
	padding: 0px 20px 0px 20px;
}

.form-style{
	align-items: center;
}

.textarea {
	background-color: #393E46;
	color: white;
	font-size: 1rem;
	border: 1px solid white;
	border-radius: 10px;
	padding: 15px;
	margin-top: 20px;
	width: 100%;
	min-height: 200px;
	margin-bottom: 10px;
	text-decoration: none;
}

.textarea:focus {
	outline: none;
}

.submit-button {
	color: black;
	padding: 10px 75px;
	border-radius: 30px;
	outline: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 24px;
	font-weight: 600;
	margin: auto;
	display: flex;
	background-color: #FD4974;
	margin-bottom: 20px;

}


.error-message {
	margin-bottom: 5px;
}


@media screen and (max-width: 960px) {
	
	.container {
		padding: 20px;
		
	}
}
.container {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	min-height: 100vh;	
	padding: 20px 150px 20px 100px;
}



.notification_in_viewport_message_text {
	color: #3C515B;
}

.notification_in_viewport_message_button_container {
	overflow: hidden;
}

.notification_in_viewport_message_yes_button_div {
	float: left;
	margin-right: 20px;
}

.notification_in_viewport_message_yes_button {
	padding: 3px;
	background-color: #BEBEBE;
	border-radius: 5px;
	color: #2471DC;
	border-color: grey;
	border-style: solid;
	border-width: thin;
	font-weight: bold;
	cursor: pointer;
}


.notification_in_viewport_message_no_button_div {
	overflow: hidden;
}
.notification_in_viewport_message_no_button {
	padding: 3px;
	padding-left: 5px;
	padding-right: 5px;
	background-color: #BEBEBE;
	border-radius: 5px;
	color: #2471DC;
	border-color: grey;
	border-style: solid;
	border-width: thin;
	font-weight: bold;
	cursor: pointer;

}

.daily_performance_div {
	margin-top: 32px;
}

.weekly_performance_div{
	margin-top: 32px;

}

.weekly_chart_container{
    max-width: 800px;
    margin: 0 auto;
}

.weekly_performance_chart{
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background-color: #393E46;
	margin-top: 32px;
	padding: 30px 30px 60px 30px;

	
}

.progress_bars {
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	justify-content: space-between;
	min-width: 350px;
	height: 500px; 
	position: relative;
}

.day_tracker { 
  height: 100%;  /* Can be anything */
  width: 20px;
  position: relative;
  margin: 0 auto;
  /*background: #555;*/
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
}

.day_progress_bar {
  display: block;
  width: 100%;
  position: absolute;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #0DFBC0;
  background-image: linear-gradient(45deg, red, transparent);
  bottom: 0;
  left:0;
}

.day_goal{
	position: relative;
	font-size: 12px;
	color: yellow;
}

.day_name {
	margin-top: 10px;
}

.white_line {
	width: 100%;
	height: 47px;
	border-top: 1px solid white;
	position: absolute;
}

.yellow_line {
	width: 100%;
	height: 47px;
	border-top: 1px solid yellow;
	position: absolute;

}


@media screen and (max-width: 768px){

	.container {
		color: white;
		width:100%;
		display: flex;
		flex-direction: column;
		justify-content: top;
		align-items: left;
		object-fit: contain;
		font-weight: 500;
		font-size: 18px; 
		min-height: 100vh;	
		padding: 20px 50px 20px 50px;
	}

	.weekly_chart_container{
	    margin: 0 auto;
	}

	.weekly_performance_chart{
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		background-color: #393E46;
		min-width: -webkit-fit-content;
		min-width: -moz-fit-content;
		min-width: fit-content;
		margin-top: 16px;
		padding: 15px 15px 30px 15px;
		
	}

	.progress_bars {
		display: flex;
		flex-direction: row;
		align-content: flex-start;
		justify-content: space-between;
		min-width: 100px;
		height: 250px; 
		position: relative;
	}

	.day_tracker { 
	  height: 100%;  /* Can be anything */
	  width: 15px;
	  position: relative;
	  margin: 0 auto;
	  /*background: #555;*/
	  border-radius: 25px;
	  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
	}

	.day_progress_bar {
		display: block;
		width: 100%;
		position: absolute;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		background-color: #0DFBC0;
		background-image: linear-gradient(45deg, red, transparent);
		bottom: 0;
		left:0;
	}

	.day_goal{
		position: relative;
		font-size: 12px;
		color: yellow;
	}

	.day_name {
		margin-top: 5px;
		font-size: 15px;
		margin-right: 10px;
	}

	.white_line {
		width: 100%;
		height: 47px;
		border-top: 1px solid white;
		position: absolute;
	}

	.yellow_line {
		width: 100%;
		height: 47px;
		border-top: 1px solid yellow;
		position: absolute;

	}

}


.cards {
  padding: 4rem;
  background: #000000;
  color: #ffffff;
  font-weight: 500;
}

.card__section__title {
  text-align: left;
  font-size: 48px;
  margin-bottom: 32px;
  font-weight: 600;
}

.notifications-image {
  max-width: 100%;
  height: 500px;
  background: url(/static/media/habit-trigger-notifications.a65774e4.webp) no-repeat;
  background-position: top left;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}


.cards__wrapper {
  position: relative;
  margin-top: 32px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #393E46;
  min-width: 350px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 45%;
  overflow: hidden;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 81px;
  max-width: 100%;
  height: 81px;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
}


.cards__item__info {
  padding: 5px 30px 30px;
  text-align: center;
}

.cards__item__text {
  color: #ffffff;
  font-size: 24px;
  line-height: 24px;

}

.card__item__percent {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.meter { 
  height: 20px;  /* Can be anything */
  position: relative;
  background: #555;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
}

.progress_bar {
  display: block;
  height: 100%;
  position: absolute;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #2BE3FD;
  background-image: linear-gradient(45deg, black, transparent);
}


@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


@media only screen and (max-width: 500px) {
  .notifications-image {
      background: url(/static/media/habit-trigger-notifications-mobile.e4b11939.webp) no-repeat;
      background-position: top left;
      background-size: contain;
      height: 400px;
  }
}

.ArticlePreviewContianer {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	padding: 20px 150px 20px 100px;
	border-bottom: 1.5px solid #3C515B;
	padding-top: 50px;
	padding-bottom: 50px;
	min-height: 100vh;
	padding: 20px 150px 20px 100px;
	padding-top: 64px !important;
    padding-right: 18% !important;
    padding-bottom: 64px !important;
    padding-left: 18% !important;
    font-size: 18px;
}

a {
	text-decoration: none;
}

h1 {
	color:white ;
	background: black;
}

div{
	color:white;
}

.ArticlePreview-btn {
	color: black;
	background-color: #FD4974;
	padding: 10px 75px;
	border-radius: 30px;
	outline: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 24px;
	font-weight: 600;
	margin: auto;
	margin-top: 20px;
}

 .img-responsive{
 	
 	max-width:100%;
    height:auto;

 }
.blog-article-container {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	min-height: 100vh;	
	padding: 20px 150px 20px 100px;
	padding-top: 64px !important;
    padding-right: 18% !important;
    padding-bottom: 64px !important;
    padding-left: 18% !important;
    font-size: 18px;
}

a {
    text-decoration: none;
    color: lightblue;
    word-break: break-word;
}

h1 {
	color:white ;
	background: black;
}

div{
	color:white;
}

.main-article-content {
}


.blog-article-btn {
	color: black;
	background-color: #3EE2CA;
	padding: 10px 75px;
	border-radius: 30px;
	outline: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 24px;
	font-weight: 600;
	margin: auto;
	margin-top: 20px;
}


