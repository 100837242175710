.cards {
  padding: 4rem;
  background: #000000;
  color: #ffffff;
  font-weight: 500;
}

.card__section__title {
  text-align: left;
  font-size: 48px;
  margin-bottom: 32px;
  font-weight: 600;
}

.notifications-image {
  max-width: 100%;
  height: 500px;
  background: url('../assets/images/habit-trigger-notifications.webp') no-repeat;
  background-position: top left;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}


.cards__wrapper {
  position: relative;
  margin-top: 32px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #393E46;
  min-width: 350px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 45%;
  overflow: hidden;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 81px;
  max-width: 100%;
  height: 81px;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
}


.cards__item__info {
  padding: 5px 30px 30px;
  text-align: center;
}

.cards__item__text {
  color: #ffffff;
  font-size: 24px;
  line-height: 24px;

}

.card__item__percent {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.meter { 
  height: 20px;  /* Can be anything */
  position: relative;
  background: #555;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
}

.progress_bar {
  display: block;
  height: 100%;
  position: absolute;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #2BE3FD;
  background-image: linear-gradient(45deg, black, transparent);
}


@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


@media only screen and (max-width: 500px) {
  .notifications-image {
      background: url('../assets/images/habit-trigger-notifications-mobile.webp') no-repeat;
      background-position: top left;
      background-size: contain;
      height: 400px;
  }
}
