.container {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	min-height: 100vh;	
	padding: 20px 150px 20px 100px;
}



.notification_in_viewport_message_text {
	color: #3C515B;
}

.notification_in_viewport_message_button_container {
	overflow: hidden;
}

.notification_in_viewport_message_yes_button_div {
	float: left;
	margin-right: 20px;
}

.notification_in_viewport_message_yes_button {
	padding: 3px;
	background-color: #BEBEBE;
	border-radius: 5px;
	color: #2471DC;
	border-color: grey;
	border-style: solid;
	border-width: thin;
	font-weight: bold;
	cursor: pointer;
}


.notification_in_viewport_message_no_button_div {
	overflow: hidden;
}
.notification_in_viewport_message_no_button {
	padding: 3px;
	padding-left: 5px;
	padding-right: 5px;
	background-color: #BEBEBE;
	border-radius: 5px;
	color: #2471DC;
	border-color: grey;
	border-style: solid;
	border-width: thin;
	font-weight: bold;
	cursor: pointer;

}

.daily_performance_div {
	margin-top: 32px;
}

.weekly_performance_div{
	margin-top: 32px;

}

.weekly_chart_container{
    max-width: 800px;
    margin: 0 auto;
}

.weekly_performance_chart{
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background-color: #393E46;
	margin-top: 32px;
	padding: 30px 30px 60px 30px;

	
}

.progress_bars {
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	justify-content: space-between;
	min-width: 350px;
	height: 500px; 
	position: relative;
}

.day_tracker { 
  height: 100%;  /* Can be anything */
  width: 20px;
  position: relative;
  margin: 0 auto;
  /*background: #555;*/
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
}

.day_progress_bar {
  display: block;
  width: 100%;
  position: absolute;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #0DFBC0;
  background-image: linear-gradient(45deg, red, transparent);
  bottom: 0;
  left:0;
}

.day_goal{
	position: relative;
	font-size: 12px;
	color: yellow;
}

.day_name {
	margin-top: 10px;
}

.white_line {
	width: 100%;
	height: 47px;
	border-top: 1px solid white;
	position: absolute;
}

.yellow_line {
	width: 100%;
	height: 47px;
	border-top: 1px solid yellow;
	position: absolute;

}


@media screen and (max-width: 768px){

	.container {
		color: white;
		width:100%;
		display: flex;
		flex-direction: column;
		justify-content: top;
		align-items: left;
		object-fit: contain;
		font-weight: 500;
		font-size: 18px; 
		min-height: 100vh;	
		padding: 20px 50px 20px 50px;
	}

	.weekly_chart_container{
	    margin: 0 auto;
	}

	.weekly_performance_chart{
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		background-color: #393E46;
		min-width: fit-content;
		margin-top: 16px;
		padding: 15px 15px 30px 15px;
		
	}

	.progress_bars {
		display: flex;
		flex-direction: row;
		align-content: flex-start;
		justify-content: space-between;
		min-width: 100px;
		height: 250px; 
		position: relative;
	}

	.day_tracker { 
	  height: 100%;  /* Can be anything */
	  width: 15px;
	  position: relative;
	  margin: 0 auto;
	  /*background: #555;*/
	  -moz-border-radius: 25px;
	  -webkit-border-radius: 25px;
	  border-radius: 25px;
	  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
	}

	.day_progress_bar {
		display: block;
		width: 100%;
		position: absolute;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		background-color: #0DFBC0;
		background-image: linear-gradient(45deg, red, transparent);
		bottom: 0;
		left:0;
	}

	.day_goal{
		position: relative;
		font-size: 12px;
		color: yellow;
	}

	.day_name {
		margin-top: 5px;
		font-size: 15px;
		margin-right: 10px;
	}

	.white_line {
		width: 100%;
		height: 47px;
		border-top: 1px solid white;
		position: absolute;
	}

	.yellow_line {
		width: 100%;
		height: 47px;
		border-top: 1px solid yellow;
		position: absolute;

	}

}

