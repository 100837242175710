.container {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	min-height: 100vh;	
	padding: 20px 150px 20px 100px;
}

.generic {
	margin-bottom: 50px;
}

.site-under-construction {
	margin-top: 25px;
}

.text-container {
	background-color: #393E46;
	border-radius: 10px;
	display: flex;	
	flex-direction: column;
	align-items: center;
	max-width: 500px;
}

.form-area {
	width: 100%;
	padding: 0px 20px 0px 20px;
}

.form-style{
	align-items: center;
}

.textarea {
	background-color: #393E46;
	color: white;
	font-size: 1rem;
	border: 1px solid white;
	border-radius: 10px;
	padding: 15px;
	margin-top: 20px;
	width: 100%;
	min-height: 200px;
	margin-bottom: 10px;
	text-decoration: none;
}

.textarea:focus {
	outline: none;
}

.submit-button {
	color: black;
	padding: 10px 75px;
	border-radius: 30px;
	outline: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 24px;
	font-weight: 600;
	margin: auto;
	display: flex;
	background-color: #FD4974;
	margin-bottom: 20px;

}


.error-message {
	margin-bottom: 5px;
}


@media screen and (max-width: 960px) {
	
	.container {
		padding: 20px;
		
	}
}