.blog-article-container {
	background-color: black;
	color: white;
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: left;
	object-fit: contain;
	font-weight: 500;
	font-size: 24px; 
	min-height: 100vh;	
	padding: 20px 150px 20px 100px;
	padding-top: 64px !important;
    padding-right: 18% !important;
    padding-bottom: 64px !important;
    padding-left: 18% !important;
    font-size: 18px;
}

a {
    text-decoration: none;
    color: lightblue;
    word-break: break-word;
}

h1 {
	color:white ;
	background: black;
}

div{
	color:white;
}

.main-article-content {
}


.blog-article-btn {
	color: black;
	background-color: #3EE2CA;
	padding: 10px 75px;
	border-radius: 30px;
	outline: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 24px;
	font-weight: 600;
	margin: auto;
	margin-top: 20px;
}

